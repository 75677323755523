import request from "@/base/util/request.js";
//单个文件上传
export const fileupload = (data) => {
    return request({
        url: "common/backstage/file/fileupload",
        method: "post",
        hdaders: {
            'Content-Type': "multipart/form-data"
        },
        data,
    })
}

// 文件下载
// common/backstage/file/download
export const fileDownload = (params) => {
    return request({
        url: "common/backstage/file/download",
        method: "get",
        params,
        responseType: "blob",
    })
}