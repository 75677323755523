<template>
  <div class="vue-ueditor-box">
    <vue-ueditor-wrap
      refs="ueditor"
      v-model="editorContent"
      :config="myConfig"
      @ready="handleReady"
    ></vue-ueditor-wrap>
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: {
    VueUeditorWrap,
  },
  props: {
    editorChildContent: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorContent: "",
      editor: null,
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: "/qixianggongyingpingtai_api/common/app/file/ueditorFileUpload",
        imageActionName: "uploadimage" /* 执行上传图片的action名称 */,
        imageFieldName: "file" /* 提交的图片表单名称 */,
        imageAllowFiles: [".png", ".jpg", ".jpeg", ".gif", ".bmp"],
        imagePathFormat:
          "/qixianggongyingpingtai_api/common/app/file/fileDownLoad?filePath=/{yyyy}{mm}{dd}/{filename}", //回显图片
        imageUrlPrefix: "", //图片前缀
        UEDITOR_HOME_URL: "/UEditor/", //一定要注意此处！！！！
      },
    };
  },
  watch: {
    editorContent(val) {
      this.$emit("getContent", val);
    },
  },
  methods: {
    handleReady() {},
    getText() {
      // 获取editor中的文本
      // console.log(this.editor.getContent());
    },
    setText(val) {
      this.editorContent = val;
    },
  },
  mounted() {
    // this.editor = UE.getEditor(this.$refs["ueditor"]);
    this.editorContent = this.editorChildContent || "";
  },
  // destroyed() {
  //   // 将editor进行销毁
  //   this.editor.destroy();
  // },
};
</script>

<style lang="less" scoped>
</style>